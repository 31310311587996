import { DefaultTeaser as ChameleonDefaultTeaser } from '@mediahuis/chameleon-react';
import { ComponentType, FC, ReactElement } from 'react';

import { getThemeDataAttributes } from '@hubcms/utils-theme';
import { defaultTeaserImageRatio } from '@hubcms/brand';
import { TeaserImageFormat } from '@hubcms/domain-images';
import { isTeaserImage } from '@hubcms/domain-teaser';

import { DefaultTeaserProps as ChameleonDefaultTeaserProps } from '../../domain/chameleon/default-teaser-props';
import { inheritDefaultTeaserVerticalImageSizes } from '../../utils/inheritDefaultTeaserVerticalImageSizes';
import { NativeTeaserImage, NativeTeaserSimpleImage } from '../NativeTeaserImage';
import { TeaserLink } from '../TeaserLink';

import { getChameleonProps } from './getChameleonProps';
import { DefaultTeaserProps } from './types';

// eslint-disable-next-line react/jsx-no-useless-fragment
const EmptyImage: FC = () => <></>;

function getTeaserImage({
  isImageHidden = false,
  ...props
}: Omit<DefaultTeaserProps, 'imageFormat'> & { imageFormat: TeaserImageFormat }): ReactElement | null {
  if (isImageHidden) {
    return null;
  }
  if (props.image) {
    const isPodcast = props.contentType === 'podcast';
    const sizes = inheritDefaultTeaserVerticalImageSizes(props.orientation, props.size, props.imageSizes);
    if (isTeaserImage(props.image)) {
      return (
        <NativeTeaserImage
          image={props.image}
          format={isPodcast ? 'oneOne' : props.imageFormat}
          sizes={sizes}
          isPriority={props.isImagePriority}
        />
      );
    }
    return (
      <NativeTeaserSimpleImage
        image={props.image}
        format={isPodcast ? 'oneOne' : props.imageFormat}
        sizes={sizes}
        isPriority={props.isImagePriority}
      />
    );
  }
  // This lets Chameleon Teaser set the placeholder, so we don't have to
  return <EmptyImage />;
}

const DefaultTeaser: FC<DefaultTeaserProps> = ({ imageFormat = defaultTeaserImageRatio, ...props }) => {
  const link =
    typeof props.link === 'string' && props.trackingData ? (
      <TeaserLink url={props.link} isExternal={props.isExternal} trackingData={props.trackingData} />
    ) : (
      props.link
    );
  const chameleonProps = getChameleonProps({ ...props, imageFormat }, getTeaserImage({ ...props, imageFormat }));

  const themeDataAttributes = props.theme ? getThemeDataAttributes(props.theme) : null;
  const Component = ChameleonDefaultTeaser as ComponentType<ChameleonDefaultTeaserProps>;
  return (
    <Component
      {...chameleonProps}
      link={link}
      className={props.className}
      data-article-id={props.id}
      data-list-id={props.list?.id}
      {...themeDataAttributes}
    />
  );
};

export default DefaultTeaser;
