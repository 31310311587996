// eslint-disable-next-line import/no-namespace
import * as defaultTokens from '@mediahuis/chameleon-theme-wl/tokens';
// eslint-disable-next-line import/no-namespace
import * as desktopTokens from '@mediahuis/chameleon-theme-wl/lib/tokens/js/desktop';

import { DefaultTeaserOrientation } from '@hubcms/domain-teaser';
import { ImageSizes } from '@hubcms/domain-images';
import { Viewport, PerViewport } from '@hubcms/domain-styling';

type TeaserSize = 'sm' | 'md' | 'lg';

function uppercaseTeaserSize(teaserSize: TeaserSize) {
  return `${teaserSize.charAt(0).toUpperCase()}${teaserSize.slice(1)}`;
}

function inheritSizeFor(viewPort: Viewport, teaserSize: TeaserSize, tryDesktop?: boolean) {
  const key = `horizontalTeaserImageDefault${uppercaseTeaserSize(teaserSize)}Width`;
  if (viewPort === 'xs' && !tryDesktop) {
    return defaultTokens[key];
  }
  return desktopTokens[key as keyof typeof desktopTokens] || defaultTokens[key];
}

const viewPorts: Viewport[] = ['xs', 'sm', 'md', 'lg', 'xl'];

export function findViewportOrInherit(viewPort: Viewport, prop: PerViewport<TeaserSize>): TeaserSize {
  if (prop[viewPort]) {
    return prop[viewPort] as TeaserSize;
  }
  const viewPortIdx = viewPorts.indexOf(viewPort);
  const biggestInheritableViewPort = viewPorts
    .slice(0, viewPortIdx)
    .reverse()
    .find(vP => prop[vP]);
  return biggestInheritableViewPort ? (prop[biggestInheritableViewPort] as TeaserSize) : 'lg';
}

export function inheritDefaultTeaserVerticalImageSizes(
  orientation: DefaultTeaserOrientation | PerViewport<DefaultTeaserOrientation>,
  teaserSize: TeaserSize | Partial<PerViewport<TeaserSize>>,
  imageSizes?: ImageSizes,
): ImageSizes | undefined {
  // Always vertical
  if (orientation === 'vertical') {
    return imageSizes;
  }
  // Always horizontal
  if (orientation === 'horizontal') {
    // Always one size
    if (typeof teaserSize === 'string') {
      return { xs: inheritSizeFor('xs', teaserSize), sm: inheritSizeFor('sm', teaserSize) };
    }
    // Match size
    const teaserSizes = Object.entries(teaserSize);
    const inheritedImageSizes = teaserSizes.map(([k, v]) => [k, inheritSizeFor(k as Viewport, v)]);
    return Object.fromEntries(inheritedImageSizes);
  }
  // Sometimes vertical, sometimes horizontal
  const orientations = Object.entries(orientation);
  const horizontalViewports = orientations.filter(([, value]) => value === 'horizontal');

  let horizontalImageSizes = {};
  // Always one size when horizontal
  if (typeof teaserSize === 'string') {
    horizontalImageSizes = Object.fromEntries(horizontalViewports.map(([k]) => [k, inheritSizeFor(k as Viewport, teaserSize)]));
  } else {
    // Inherit from teaserSize
    horizontalImageSizes = Object.fromEntries(
      horizontalViewports.map(([k]) => [
        k,
        inheritSizeFor(k as Viewport, findViewportOrInherit(k as Viewport, teaserSize), k !== 'xs'),
      ]),
    );
  }

  return { ...imageSizes, ...horizontalImageSizes };
}
